import React, {
  
  
  useEffect,
  useMemo,
  useState,
} from "react";


import TitledImportanceTrack from "./titledimportancetrack";


//import { CalderonBigWigTracksMetadata } from "./consts";
export type GenomicRange = {
  chromosome?: string;
  start: number;
  end: number;
  onHeightChanged?: (i: number) => void;
};

type DeepshapTracksProps = {
  assembly?: string;
  domain: GenomicRange;
  onHeightChanged?: (i: number) => void;
  accession?: string;
 
};




const DeepshapTracks: React.FC<DeepshapTracksProps> = ({
  assembly,
  domain,
  accession,
  onHeightChanged
}) => {

//  const r = defaultcelltypes && CalderonBigWigTracksMetadata.filter(c=>defaultcelltypes.includes(c.celltype_name))

  
  // manage displayed tracks, compute height, and pass height back to parent
  const [displayedTracks, setDisplayedTracks] = useState<[string, string][]>(assembly==="mm10" ? [
      
    [
      `${accession} Count Scores`,
      `https://mitra.stanford.edu/kundaje/oak/vir/tfatlas/shap/release_run_1/mouse/bestfold/${accession}/counts_scores.bw`,
    ],
    [
      `${accession} Profile Scores`,
      `https://mitra.stanford.edu/kundaje/oak/vir/tfatlas/shap/release_run_1/mouse/bestfold/${accession}/profile_scores.bw`,
    ],
    [
      `${accession} Counts Mean Shap Scores`,
      `https://mitra.stanford.edu/kundaje/oak/vir/tfatlas/shap/release_run_1/mouse/meanshap/${accession}/counts_mean_shap_scores.bw`,
    ],
    [
      `${accession} Profile Mean Shap Scores`,
      `https://mitra.stanford.edu/kundaje/oak/vir/tfatlas/shap/release_run_1/mouse/meanshap/${accession}/profile_mean_shap_scores.bw`,
    ]
   
  ]:[
    
     
    [
      `${accession} Count Scores`,
      `https://mitra.stanford.edu/kundaje/oak/vir/tfatlas/shap/release_run_1/bestfold/${accession}/counts_scores.bw`,
    ],
    [
      `${accession} Profile Scores`,
      `https://mitra.stanford.edu/kundaje/oak/vir/tfatlas/shap/release_run_1/bestfold/${accession}/profile_scores.bw`,
    ],
    [
      `${accession} Counts Mean Shap Scores`,
      `https://mitra.stanford.edu/kundaje/oak/vir/tfatlas/shap/release_run_1/meanshap/${accession}/counts_mean_shap_scores.bw`,
    ],
    [
      `${accession} Profile Mean Shap Scores`,
      `https://mitra.stanford.edu/kundaje/oak/vir/tfatlas/shap/release_run_1/meanshap/${accession}/profile_mean_shap_scores.bw`,
    ]
    ]
 );

 console.log(setDisplayedTracks)
  const height = useMemo(
    () =>
      130 +
      (displayedTracks.length * 130) - 130,
    [displayedTracks, domain]
  );
  
  useEffect(() => {
    onHeightChanged && onHeightChanged(height);
  }, [onHeightChanged, height]);

  // manage settings modal
  const [settingsMousedOver, setSettingsMousedOver] = useState(false);
  
console.log(domain,"deepshap domain")
  

  return (
    <>
  
    <g className="encode-fetal-brain">
      <rect y={10} height={55} fill="none" width={1400} />
    </g>
      {displayedTracks
        .map((x, i) => (
            <TitledImportanceTrack            
            key={`${i}_${domain.start}_${domain.end}`}
            transform={`translate(0,${130 * i})`}
            title={x[0]}
            height={130}
            width={2000}
            assembly={assembly}
            signalURL={ `${x[1].replace('bigWig','profile_scores.bw')}`}
      //      imputedSignalURL={`${x[1].replace('bigWig','profile_scores.bw')}`}
            domain={domain}            
            neutralRegions={[]}
          />
        ))}
      {settingsMousedOver && (
        <rect
          width={2000}
          height={height}
          transform="translate(0,-0)"
          fill="#ab3f00"
          fillOpacity={0.1}
        />
      )}
      <rect
        transform="translate(0,0)"
        height={height}
        width={40}
        fill="#ffffff"
      />
      <rect
        height={height}
        width={15}
        fill="#ab3f00"
        stroke="#000000"
        fillOpacity={settingsMousedOver ? 1 : 0.6}
        onMouseOver={() => setSettingsMousedOver(true)}
        onMouseOut={() => setSettingsMousedOver(false)}
        strokeWidth={1}
        transform="translate(20,0)"
      
      />
      <text
        transform={`rotate(270) translate(-${height / 2},12)`}
        textAnchor="middle"
        fill="#ab3f00"
      >
        Deep Shap Tracks
      </text>
    </>
  );
};
export default DeepshapTracks;