import { GraphQLImportanceTrack } from "bpnet-ui";
import { ImportanceTrackDataPoint } from "bpnet-ui/dist/components/ImportanceTrack/ImportanceTrack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EmptyTrack, FullBigWig } from "umms-gb";
import { RawLogo, DNAAlphabet } from "logojs-react";
import { BigWigData, BigBedData, BigZoomData } from "bigwig-reader";
import { gql, useQuery } from "@apollo/client";
import { ValuedPoint } from "umms-gb/dist/utils/types";

import {
    //BigRequest,
    RequestError,
  } from "umms-gb/dist/components/tracks/trackset/types";
  
import { ApolloClient, InMemoryCache } from "@apollo/client"

const client = new ApolloClient({
  uri: "https://factorbook.api.wenglab.org/graphql",
  cache: new InMemoryCache(),
})

export function linearTransform(
    d: [number, number],
    r: [number, number]
  ): (v: number) => number {
    return (v) => r[0] + (r[1] - r[0]) * ((v - d[0]) / (d[1] - d[0]));
  }
  

  export const BIG_QUERY = gql`
  query BigRequests($bigRequests: [BigRequest!]!) {
    bigRequests(requests: $bigRequests) {
      data
      error {
        errortype
        message
      }
    }
  }
`;

export type BigResponseData =
  | BigWigData[]
  | BigBedData[]
  | BigZoomData[]
  | ValuedPoint[];

export type BigResponse = {
  data: BigResponseData;
  error: RequestError;
};

export type BigQueryResponse = {
  bigRequests: BigResponse[];
};

export type GenomicRange = {
    chromosome?: string;
    start: number;
    end: number;
  };
  
type TitledImportanceTrackProps = {
  onHeightChanged?: (height: number) => void;
  onImportantRegionsLoaded?: (regions: BigBedData[]) => void;
  height: number;
  title: string;
  transform?: string;
  domain: GenomicRange;
  signalURL: string;
  imputedSignalURL?: string;
  positiveRegionURL?: string;
  negativeRegionURL?: string;
  neutralRegions?: BigBedData[];
  width: number;
  color?: string;
  assembly?: string;
};

type TitledImportanceTrackHighlight = {
  coordinates: [number, number];
  sequence: string;
  motif: MotifMatch;
  reverseComplement?: boolean;
};

type MotifMatch = {
  pwm: number[][];
  tomtom_matches: {
    target_id: string;
    jaspar_name: string;
    e_value: number;
  }[];
};

type MotifResponse = {
  data: {
    meme_motif_search: {
      results: [
        {
          motif: MotifMatch;
          reverseComplement: boolean;
        }
      ];
    }[];
  };
};


const MOTIF_QUERY = `
query MemeMotifSearch($pwms: [[[Float!]]]!) {
    meme_motif_search(pwms: $pwms, assembly: "GRCh38", limit: 1, offset: 1) {
      results {
        motif {
          pwm
          tomtom_matches {
            target_id
            jaspar_name
            e_value
          }
        }
        reverseComplement
      }
    }
  }  
`;


function seqToPWM(sequence: string[]): number[][] {
  const M = {
    A: [1, 0, 0, 0],
    C: [0, 1, 0, 0],
    G: [0, 0, 1, 0],
    T: [0, 0, 0, 1],
  };
  return sequence.map((x) => M[x]);
}

export const reverseComplement = (ppm: number[][]): number[][] =>
  ppm && ppm[0] && ppm[0].length === 4
    ? ppm.map((inner) => inner.slice().reverse()).reverse()
    : ppm
        .map((entry) => [
          entry[3],
          entry[2],
          entry[1],
          entry[0],
          entry[5],
          entry[4],
        ])
        .reverse();

export const logLikelihood = (backgroundFrequencies: any) => (r: any) => {
  let sum = 0.0;
  r.map(
    (x: any, i: any) =>
      (sum +=
        x === 0 ? 0 : x * Math.log2(x / (backgroundFrequencies[i] || 0.01)))
  );
  return r.map((x: any) => {
    const v = x * sum;
    return v <= 0.0 ? 0.0 : v / 2;
  });
};

type TOMTOMMatch = {
  e_value: number;
  jaspar_name?: string | null;
  target_id: string;
};

function best(x: TOMTOMMatch[]): TOMTOMMatch {
  return x.sort((a, b) => a.e_value - b.e_value)[0];
}




const TitledImportanceTrack: React.FC<TitledImportanceTrackProps> = (props) => {
  const {
    height,
    transform,
    signalURL,
    width,
    title,
    domain,
    imputedSignalURL,
    color,
    negativeRegionURL,
    positiveRegionURL,
    onImportantRegionsLoaded,
    assembly
  } = props;

  console.log(assembly,"asm")
  useEffect(
    () => props.onHeightChanged && props.onHeightChanged(height),
    [height, props.onHeightChanged]
  );

  const coordinateMap = useCallback(
    (coordinate: number) => (coordinate * width) / (domain.end - domain.start),
    [domain]
  );

  const [selectedHighlight, setSelectedHighlight] = useState<number | null>(
    null
  );
  const [highlights, setHighlights] = useState<
    TitledImportanceTrackHighlight[]
  >([]);
  const onSelectionEnd = useCallback(
    (coordinates: [number, number], values: ImportanceTrackDataPoint[]) => {
      const sequence = values.map((x) => x.base);
      fetch("https://ga.staging.wenglab.org/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: MOTIF_QUERY,
          variables: { pwms: [seqToPWM(sequence)] },
        }),
      })
        .then((response) => response.json())
        .then((data) =>
          setHighlights([
            ...highlights,
            {
              sequence: sequence.join(""),
              coordinates,
              motif: (data as MotifResponse).data.meme_motif_search[0]
                .results[0].motif,
              reverseComplement: (data as MotifResponse).data
                .meme_motif_search[0].results[0].reverseComplement,
            },
          ])
        )
        .catch((error) => console.error(error));
    },
    [highlights]
  );
 
  const bigRequests = useMemo(() => {
    const bw = [
      {
        chr1: domain.chromosome!,
        start: domain.start,
        end: domain.end,
        preRenderedWidth: width,
        url: imputedSignalURL,
      },
    ];
    if (negativeRegionURL === undefined || positiveRegionURL === undefined)
      return bw;
    return [
      ...bw,
      {
        chr1: domain.chromosome!,
        start: domain.start,
        end: domain.end,
        preRenderedWidth: width,
        url: negativeRegionURL,
      },
      {
        chr1: domain.chromosome!,
        start: domain.start,
        end: domain.end,
        preRenderedWidth: width,
        url: positiveRegionURL,
      },
    ];
  }, [domain.chromosome, domain.start, domain.end, negativeRegionURL, positiveRegionURL, imputedSignalURL]);
  const { data, loading } = useQuery<BigQueryResponse>(BIG_QUERY, {
    variables: { bigRequests },
    skip: imputedSignalURL === undefined,
    client
  });

  useEffect(() => {
    if (
      loading ||
      !data ||
      !onImportantRegionsLoaded ||
      positiveRegionURL === undefined ||
      negativeRegionURL === undefined
    )
      return;
    const p = data.bigRequests.length - 2;
    const n = data.bigRequests.length - 1;
    onImportantRegionsLoaded([
      ...data.bigRequests[p].data,
      ...data.bigRequests[n].data,
    ] as BigBedData[]);
  }, [data, loading]);
const seqUrl = assembly=="mm10" ? `gs://gcp.wenglab.org/mm10.2bit` : `gs://gcp.wenglab.org/hg38.2bit`
console.log("sequrl", seqUrl)
  return (
    <g transform={transform}>
      {!loading && imputedSignalURL !== undefined && <EmptyTrack transform="" text={title} height={30} width={2000} id="" />}
      {!loading && imputedSignalURL !== undefined && (
        <FullBigWig
          transform="translate(0,15)"
          width={2000}
          height={
            height - (props.domain.end - props.domain.start <= 10000 ? 85 : 30)
          }
          domain={domain}
          id="NeuN+"
          color={color}
          data={data?.bigRequests[0].data as BigWigData[]}
          
        />
      )}
      {props.domain.end - props.domain.start <= 10000 && (
        <g
          transform={`translate(0,${
            loading || imputedSignalURL === undefined ? 30 : 80
          })`}
        >
          <EmptyTrack
            transform=""
            text={title}
            height={5}
            width={2000}
            id=""
          />
          <GraphQLImportanceTrack
            width={width}
            height={
              height - (loading || imputedSignalURL === undefined ? 30 : 80)
            }
            endpoint="https://ga.staging.wenglab.org"
            signalURL={signalURL}
            sequenceURL={seqUrl }
            coordinates={domain as any}
            allowSelection
            onSelectionEnd={onSelectionEnd}
          />
        </g>
      )}
      {highlights.map((highlight, i) => (
        <rect
          key={`highlight_${i}`}
          transform={`translate(${coordinateMap(highlight.coordinates[0])},30)`}
          width={
            coordinateMap(highlight.coordinates[1]) -
            coordinateMap(highlight.coordinates[0])
          }
          fill="#ff0000"
          fillOpacity={0.1}
          height={100}
          onMouseOver={() => setSelectedHighlight(i)}
          onMouseOut={() => setSelectedHighlight(null)}
        />
      ))}
      {selectedHighlight !== null &&
        highlights[selectedHighlight] &&
        highlights[selectedHighlight].motif && (
          <g
            transform={`translate(${
              coordinateMap(highlights[selectedHighlight].coordinates[0]) - 120
            },-150)`}
          >
            <rect
              fill="#ffffff"
              stroke="#000000"
              strokeWidth={2}
              width={300}
              height={200}
            ></rect>
            <g transform="translate(10,60)">
              <RawLogo
                alphabet={DNAAlphabet}
                values={(highlights[selectedHighlight].reverseComplement
                  ? reverseComplement(highlights[selectedHighlight].motif.pwm)
                  : highlights[selectedHighlight].motif.pwm
                ).map(logLikelihood([0.25, 0.25, 0.25, 0.25]))}
                glyphWidth={10}
                stackHeight={50}
                x={0}
                y={0}
              />
              {highlights[selectedHighlight].motif.tomtom_matches &&
                highlights[selectedHighlight].motif.tomtom_matches.length >
                  0 && (
                  <text x={0} y={80} fontWeight="bold">
                    {best(
                      highlights[selectedHighlight].motif.tomtom_matches
                    ).target_id.startsWith("MA")
                      ? best(highlights[selectedHighlight].motif.tomtom_matches)
                          .jaspar_name
                      : best(highlights[selectedHighlight].motif.tomtom_matches)
                          .target_id}
                  </text>
                )}
            </g>
          </g>
        )}
    </g>
  );
};
export default TitledImportanceTrack;
