import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { ApiContext } from 'apicontext';

import { inflate } from 'pako';
import { associateBy } from 'queryz';

import { DataTable, DataTableColumn } from 'ts-ztable';
import { includeTargetTypes, excludeTargetTypes } from '../../consts';
import TfDetails from './tfdetails';
import { TF_INFO_QUERY } from './queries';
import { DatasetQueryResponse } from './types';
import { TargetPartitionedDatasetCollection } from 'components/types';

const SEQUENCE_SPECIFIC = new Set([ "Known motif", "Inferred motif" ]);

function tfColumns(species: string, tfA?: any): DataTableColumn<TargetPartitionedDatasetCollection>[] {
    return [
        {
            header: '',
            value: row => row.target.name,
            render: row => <TfDetails row={row} species={species} factor={row.target.name} label={(tfA === undefined || tfA.get(row.target.name.split("phospho")[0]) === undefined ? "" : (tfA.get(row.target.name.split("phospho")[0])["TF assessment"] as unknown as string).includes("Likely") ? "Likely sequence-specific TF - " : (SEQUENCE_SPECIFIC.has(tfA.get(row.target.name.split("phospho")[0])["TF assessment"]) ? "Sequence-specific TF - " : "Non-sequence-specific factor - "))} />,
            sort: (a, b) => b.counts.biosamples - a.counts.biosamples,
        },
    ];
}

const Tf: React.FC = () => {
    const { species } = useParams<{ species: string }>();
    const assembly = species === 'human' ? 'GRCh38' : 'mm10';
    const client = useContext(ApiContext).client;
    const { data, error, loading } = useQuery<DatasetQueryResponse>(TF_INFO_QUERY, {
        client,
        variables: {
            processed_assembly: assembly,
            replicated_peaks: true,
            include_investigatedas: includeTargetTypes,
            exclude_investigatedas: excludeTargetTypes,
        },
    });

    const [ lloading, setLoading ] = React.useState(false);
    const [ tfA, setTFA ] = React.useState<any | null>(null);

    React.useEffect( () => {
        if (!lloading) {
            fetch("/tf-assignments.json.gz")
                .then(x => x.blob())
                .then(x => x.arrayBuffer())
                .then(x => inflate(x, { to: 'string' }))
                .then(x => setTFA(associateBy(JSON.parse(x), x => (x as any)["HGNC symbol"], x => x)));
            setLoading(true);
        }
    }, [ lloading ]);

    return (
        <>
            <div style={{ height: '5em' }} />
            {loading && <Loader active />}
            {data && !error && (
                <Container>
                    <h2>
                        Browsing {data.peakDataset.partitionByTarget.length} factors with data available in {species}:
                    </h2>
                    <DataTable
                        columns={tfColumns(species, tfA)}
                        rows={data.peakDataset.partitionByTarget}
                        searchable
                        itemsPerPage={4}
                        sortColumn={0}
                        sortDescending
                    />
                </Container>
            )}
        </>
    );
};

export default Tf;
