import {  useQuery, ApolloClient, InMemoryCache, gql } from "@apollo/client"

import React, { RefObject, useEffect, useMemo, useState } from "react"
import { DenseBigBed, EmptyTrack, FullBigWig } from "umms-gb"
import { BigWigData, BigBedData, BigZoomData } from "bigwig-reader"
import { RequestError } from "umms-gb/dist/components/tracks/trackset/types"
import { ValuedPoint } from "umms-gb/dist/utils/types"

export const BIG_QUERY = gql`
  query BigRequests($bigRequests: [BigRequest!]!) {
    bigRequests(requests: $bigRequests) {
      data
      error {
        errortype
        message
      }
    }
  }
`
export const COLOR_MAP: Map<string, string> = new Map([
    [ "DNase", "#06DA93" ],
    [ "PhyloP 100-way", "#000088" ],
    [ "PhyloP 60-way", "#000088" ]
]);


export type BigResponseData = BigWigData[] | BigBedData[] | BigZoomData[] | ValuedPoint[]

export type BigResponse = {
  data: BigResponseData
  error: RequestError
}

export type BigQueryResponse = {
  bigRequests: BigResponse[]
}

export type GenomicRange = {
    chromosome?: string
    start: number
    end: number
  }
const client = new ApolloClient({ cache: new InMemoryCache(), uri: "https://ga.staging.wenglab.org/graphql" })


type DefaultTracksProps = {
  domain: GenomicRange
  onHeightChanged?: (i: number) => void
  cCREHighlight?: GenomicRange
  cCREHighlights?: Set<string>
  svgRef?: RefObject<SVGSVGElement>
  assembly: string
  oncCREClicked?: (clickedcCRE: {name: string, coordinates: {chromosome: string, start: number, end: number}}) => void
  oncCREMousedOver?: (coordinates?: GenomicRange) => void  
  oncCREMousedOut?: () => void
  onSettingsClick?: () => void
  url: string,
  accession?: string 
}

export const TitledTrack: React.FC<{
  data: BigResponseData
  assembly: string
  url: string
  title: string
  color?: string
  height: number
  transform?: string
  onHeightChanged?: (height: number) => void
  domain: GenomicRange
  svgRef?: React.RefObject<SVGSVGElement>
  oncCREMousedOver?: (coordinates?: GenomicRange) => void
  oncCREMousedOut?: () => void
  oncCREClicked?: (name) => void
  cCRECoordinateMap?: any
  biosample?: string
}> = ({
  data,
  assembly,
  url,
  title,
  height,
  domain,
  transform,
  onHeightChanged,
  svgRef,
  color,
  oncCREMousedOver,
  oncCREMousedOut,
  oncCREClicked,
  cCRECoordinateMap,
  biosample,
}) => {
  
  useEffect(() => onHeightChanged && onHeightChanged(height + 40), [height, onHeightChanged])

  return (
    <g transform={transform}>
      <EmptyTrack height={40} width={2000} transform="translate(0,8)" id="" text={title} />
      {url.endsWith(".bigBed") || url.endsWith(".bigbed") ? (
        <DenseBigBed
          width={2000}
          height={height}
          domain={domain}
          id={url}
          transform="translate(0,40)"
          data={data as BigBedData[]}
          svgRef={svgRef}
          
          
          onMouseOut={oncCREMousedOut}
          
        
        />
      ) : (
        <FullBigWig
          transform="translate(0,40)"
          width={2000}
          height={height}
          domain={domain}
          id={url}
          color={color}
          data={data as BigWigData[]}
          
        />
      )}
    </g>
  )
}

const DefaultTracks: React.FC<DefaultTracksProps> = (props) => {
  const cTracks :[string, string][] = (
    props.assembly.toLowerCase() === "mm10"
      ? [
        [ "PhyloP 60-way","gs://gcp.wenglab.org/factorbook-download/phyloP-60-way.mm10.bigWig"  ],
        [ "DNase","gs://data.genomealmanac.org/dnase.mm10.sum.bigWig"  ],
        [`ChIP-seq signal (${props.accession || ""})`,props.url]

        ]
      : [
            [ "PhyloP 100-way","gs://gcp.wenglab.org/factorbook-download/phyloP-100-way.hg38.bigWig"  ],
            [ "DNase","gs://data.genomealmanac.org/dnase.hg38.sum.bigWig"  ],
            [`ChIP-seq signal (${props.accession || ""})`,props.url]
        ]
  )

  const height = useMemo(() => cTracks.length * 80, [cTracks])
  const bigRequests = useMemo(
    () =>
      cTracks.map((x) => ({
        chr1: props.domain.chromosome!,
        start: props.domain.start,
        end: props.domain.end,
        preRenderedWidth: 2000,
        url: x[1],
      })),
    [cTracks, props]
  )
  const { data, loading } = useQuery<BigQueryResponse>(BIG_QUERY, {
    variables: { bigRequests },
    client,
  })


  useEffect(() => {
    
    props.onHeightChanged && props.onHeightChanged(height)
    
    
  }, [props.onHeightChanged, height, props])

  const [settingsMousedOver, setSettingsMousedOver] = useState(false)

  return loading || (data?.bigRequests.length || 0) < 2 ? (
    <EmptyTrack width={2000} height={40} transform="" id="" text="Loading..." />
  ) : (
    <>
      <g className="default-tracks">
        <rect y={10} height={55} fill="none" width={2000} />
      </g>
      {(data?.bigRequests || []).map((data, i) => (
        <TitledTrack
          key={i}
          assembly={props.assembly}
          oncCREMousedOut={props.oncCREMousedOut}
          oncCREMousedOver={props.oncCREMousedOver}
          oncCREClicked={props.oncCREClicked}
          height={40}
          biosample={undefined}
          url={cTracks[i][1]}
          domain={props.domain}
          title={cTracks[i][0]}
          svgRef={props.svgRef}
          data={data.data}
          color={COLOR_MAP.get(cTracks[i][0]) || "#3287a8"}
          transform={`translate(0,${i * 70})`}
          
        />
      ))}
      <g className="df-tracks">
        <rect y={110} height={55} fill="none" width={2000} />
      </g>
      {settingsMousedOver && <rect width={2000} height={height} transform="translate(0,-0)" fill="#4c1f8f" fillOpacity={0.1} />}
      <rect transform="translate(0,0)" height={height} width={40} fill="#ffffff" />
      <rect
        height={height}
        width={15}
        fill="#4c1f8f"
        stroke="#000000"
        fillOpacity={settingsMousedOver ? 1 : 0.6}
        onMouseOver={() => setSettingsMousedOver(true)}
        onMouseOut={() => setSettingsMousedOver(false)}
        strokeWidth={1}
        transform="translate(20,0)"
      />
      <text transform={`rotate(270) translate(-${height / 2},12)`} textAnchor="middle" fill="#4c1f8f">
        Default Tracks
      </text>
    </>
  )
}
export default DefaultTracks
