import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';

import { DataTable, DataTableColumn } from 'ts-ztable';
import CtDetails from './ctdetails';
import { BiosamplePartitionedDatasetCollection } from 'components/types';
import { useTFInfo } from './hooks';

function ctColumns(species: string): DataTableColumn<BiosamplePartitionedDatasetCollection>[] {
    return [
        {
            header: '',
            value: row => row.biosample.name,
            render: row => <CtDetails species={species} celltype={row.biosample.name} row={row} />,
            sort: (a, b) => b.counts.targets - a.counts.targets,
        },
    ];
}

const Ct: React.FC = () => {
    const { species } = useParams<{ species: string }>();
    const { data, loading, error } = useTFInfo();
    return (
        <>
            <div style={{ height: '5em' }} />
            {loading && <Loader active />}
            {data && !error && (
                <Container>
                    <h2>
                        Browsing {data.peakDataset.partitionByBiosample.length} cell types with data available in{' '}
                        {species}:
                    </h2>
                    <DataTable
                        columns={ctColumns(species)}
                        rows={data.peakDataset.partitionByBiosample}
                        searchable
                        itemsPerPage={4}
                        sortColumn={0}
                        sortDescending
                    />
                </Container>
            )}
        </>
    );
};
export default Ct;
